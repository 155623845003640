<template>
    <div class="">

        <!-- Content Header (Page header) -->
        <div class="">
            <!-- /.content-header -->
          <custom-alert v-if="displayAlert==true" :message="alertMessage"
                        v-on:showedAlert="resetAlert"></custom-alert>
            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <!-- Main row -->
                    <div class="row">
                        <section class="col-lg-12 connectedSortable  clublisttable table-area mt-3 pr-0 pl-0 mb-3">
                           <div class="card">
                               <div class="card-body table-responsive  table-head">
                                    <div class="row bor-bot">
                                    <div class="col-md-8 pl-0">
                                       <h1 class="m-0 text-dark pt-2 pb-4">{{ this.getSiteProps('general.admin-team-title') || 'Club Registrations List' }}</h1>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="text-right pb-3">
                                            <ol class="breadcrumb float-sm-right">
                                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                                            </ol>
                                    </div>
                                    </div>
                                  </div>
                                    <div class="table-responsive mt-3">
                                        <table class="table table-sm table-bordered table-hover" id="list_table">
                                            <thead class="thead-dark">
                                            <tr>
                                                <th>Membership Id</th>
                                                <th>{{ this.getSiteProps('general.admin-team-name') || 'Club Name' }}</th>
                                                <th>Type</th>
                                                <th>First Name</th>
                                                <th v-if="host == 'shooting'">Admin Name</th>
                                                <th v-else>Last Name</th>
                                                <th>Status</th>
                                                <th>Start Date</th>
                                                <th>Expiration On</th>
                                                <th>Waiver Signed On</th>
                                                <th>Waiver Expiration On</th>
                                                <th>Transaction id</th>
                                                <th>Region</th>
                                                <th>Age Division</th>
                                                <th>Coupon Code</th>
                                                <th>Actions</th>
                                                <th>Created At</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <!-- Data will be placed here -->
                                            <tr>
                                                <td colspan="9">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="9">&nbsp;</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                               </div>
                           </div>
                        </section>
                    </div>
                    <!-- /.row (main row) -->
                </div><!-- /.container-fluid -->
            </section>
        </div>
        <!-- /.content -->
    </div>
</template>

<script>

// import RegistrationsListTable from './RegistrationsListTable';

// import RegistrationsListTable from './RegistrationsListTable';
import $ from 'jquery';
// import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// import 'datatables.net-responsive-dt';
import moment from 'moment';
import CustomAlert from "../CustomAlert";
import axios from "axios";

export default {
    name: 'clubRegistrations',
    // components: { RegistrationsListTable },
    components: { CustomAlert },

    data() {
        return {
            host: '',
            table:'',
          alertMessage:'',
          displayAlert:'',
        }
    },

    methods: {
formatDate(value)
        {
            var date = moment(value, "YYYY-MM-DD hh:mm:ss");
            if(date.isValid()){
                return date.format('MM/DD/YYYY');
            } else {
                return 'N/A';
            }
        },
      deleteClubRegistration(id){
        this.displayAlert = false;
        this.$bvModal.msgBoxConfirm('Are you sure, do you want to delete?', {
          title: 'Please Confirm',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
            .then(async value => {
              if(String(value)=="true")
              {
                axios
                    .delete(process.env.VUE_APP_URL + "api/registrations/delete/"+id, {headers: this.adminHeaders})
                    .then((response) => {
                      this.alertMessage = "Record deleted successfully";
                      this.displayAlert = true;
                      this.table.draw();
                    })
                    .catch((error) => {
                      (error) => (this.errors = error.response.data);
                    });
                return false;
              }
            })
            .catch(err => {
              console.log(err);
            })
      },

    },

    mounted(){
        window.clubreg = this;
        var subdomain = this.subdomain;
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        this.table = $("#list_table").DataTable({
            sDom: "Rlfrtip",
            processing: true,
            serverSide: true,
            pageLength: 10,            
            // rowReorder: {
            // selector: 'td:nth-child(2)'
            // },
            responsive: true,
            ajax: {
                url: process.env.VUE_APP_URL + "api/dashboard/registrations/list/Club",
                type: 'post'
            },
            columns: [
                { data: 'confirmation_code', name: 'member.confirmation_code', render: (data)=>{return data || "N/A";}},
                { data: 'team_name', name: 'team.name', render: (data)=>{return data || "N/A";}},
                { data: 'team_type', name: 'team.type', render: (data, type, row, meta)=>{return row.team != null && row.team.club_type != undefined ? row.team.club_type : (data || 'N/A');}},
                { data: "tc_first_name", name: "team.team_contacts.first_name",  render: (data)=>{return data || "N/A";} },
                { data: "tc_last_name", name: "team.team_contacts.last_name", render: (data)=>{return data || "N/A";} },
                { data: "reg_status", name: "reg_status", orderable: false, searchable: false , render: (data)=>{return data || "N/A";}},
                { data: "r_start_date", name: "registration.start_date", orderable: true, searchable: true, render: (data)=>{return this.formatDate(data)} },
                { data: "expired_at", name: "registration.valid_thru", render: (data)=>{return this.formatDate(data)} },
                { data: "waiver_signed_at", name: "registration.valid_thru", render: (data)=>{return data || "N/A";} },
                { data: "waiver_expired_at", name: "registration.valid_thru", render: (data)=>{return data || "N/A";} },
                { data: "transaction_id", name: "registration.transaction_id" , render: (data)=>{return data || "N/A";}},
                { data: "region_code", name: "region.code", searchable: false, render: (data)=>{return data || "N/A";}, visible: this.subdomain == "waterski" },
                { data: "age_division", name: "team.age_division.name", render: (data)=>{return data || "N/A";}, visible: this.subdomain == "alliancefastpitch", searchable: false },
                { data: "coupon_code", name: "coupon_code", render: (data)=>{return data || "N/A";}, visible: this.subdomain == "waterski",orderable: false, searchable: false},
                { data: "actions", name: "actions", orderable: false, searchable: false, render: (data,type,row,meta) => {
                    var clublink = (this.subdomain == "alliancefastpitch") ? 'team-registrations' : 'club-registrations';
                    return `<a href="/admin/${clublink}/${row.team_id}"><i class="fas fa-eye"></i></a> <a class="action-club-delete" data-id="${row.id}"   href="javascript:void(0);" ><i class="fas fa-trash"></i></a>`} },
                { data: "created_at", name: "created_at", render: (data)=>{return data || "N/A";}, visible: false, searchable: false },
            ],
            /*columnDefs: [
                { targets: 7, orderable: false, searchable: false },
            ],*/
            order: [[13, "desc"]]
        });
         var vueRouter = this.$router; 
        document.addEventListener("click", function(e){
            if(e.target && e.target.parentNode.className == "actions-click"){
                var id = e.target.parentNode.getAttribute('data-id');
                if(subdomain=='alliancefastpitch')
                {
                    vueRouter.push('/admin/team-registrations/'+ id);
                }
                else
                {
                    vueRouter.push('/admin/club-registrations/'+ id);
                }
            }
          if(e.target && e.target.parentNode.className == "action-club-delete"){
            var deleteId = e.target.parentNode.getAttribute('data-id');
            window.clubreg.deleteClubRegistration(deleteId);
          }
        });

        let host = window.location.host;
        if(host.toLowerCase().includes("shooting")) this.host = "shooting";
        else this.host = "waterski";

    },
}
</script>